//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";
.metismenu li {
  display: block;
  width: 100%;
  border-top: 1px solid #eaeaea0a;
}
#sidebar-menu ul li ul.sub-menu > li {
  border: none;
}
#sidebar-menu li .active {
  color: rgb(253, 252, 252) !important;
  background: #775fa0;
  font-size: 13.5px !important;
}
.relative {
  position: relative;
  z-index: 99;
}
button.dltIcn {
  background: transparent;
  top: 0px;
  right: -10px;
  border: none;
  padding: 0;
  height: 20px;
  width: 20px;
  color: red;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  position: absolute;
}
.actionBox {
  position: relative;
}
.actionBox::-webkit-scrollbar {
  width: 3px;
}
/* Track */
.actionBox::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.actionBox::-webkit-scrollbar-thumb {
  background: #eee;
}

/* Handle on hover */
.actionBox::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}
.actionBox:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  background: #00000040;
}

.alert-dismissible.alert {
  top: 90px;
  right: 0;
  width: 100%;
  z-index: 99999;
  position: fixed;
  max-width: 320px;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0 3px 7px #27272736;
}

.accordian-bg-products {
  background-color: $gray-300;
}

.nav-link {
  cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}

input[type="range"] {
  -webkit-appearance: auto !important;
}

.chat-conversation-height {
  min-height: 500px;
}

.expand-cell {
  cursor: pointer !important;
}

.dz-message {
  text-align: center;
  padding: 25px;
}
.twitter-bs-wizard {
  min-height: 560px;
}
.actionBox select {
  font-size: 16px;
  padding: 3px 4px;
  border-radius: 3px;
  background: #fff;
  border-color: #3cc8bf;
}

.vertical-collpsed .logo-sm h4.pt-4.text-white {
  font-size: 0.851em;
}
.vertical-collpsed .navbar-brand-box {
  padding: 0 10px;
}
/* ***************seller profile*************** */
.Seller_Profile .sell_profile_head {
  background-color: #1d3557;
  width: 100%;
  height: auto;
}
.Seller_Profile .sell_profile_head img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.Seller_Profile .sell_profile_head h5,
p {
  color: #ffffff;
}
.Seller_Profile .sell_profile_head button:focus {
  outline: none;
  box-shadow: none;
}
.Seller_Profile .seller_sec_card img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.Seller_Profile .seller_sec_card p {
  color: #000;
}
.Seller_Profile .balance_card {
  background: rgb(30, 87, 153); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */

  border: none;
  /* box-shadow: 0 0 10px 5px #adb5bd7a; */
  border-radius: 20px;
  color: #ffffff;
}
/* ***************seller profile end*************** */
.dis-able{
  pointer-events: none !important;
 
}
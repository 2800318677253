
.-card-bg-{
 
    padding: 20px;
    margin: 10px;
    height: 150px;
    width: 290px;
    border-radius: 10px;
    
    background-image: linear-gradient(to bottom, #01050b, #030308, #040205, #020102, #000000);
    
    
  
    -webkit-box-shadow: 0px 20px 39px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 20px 39px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 20px 39px -4px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  .-card-bg-xl{
    padding: 15px;
    margin: 30px;
    height: 180px;
    width: 300px;
    border-radius: 10px;
    /* background-image: linear-gradient(to bottom, #01050b, #030308, #040205, #020102, #000000); */
    background-color: #b960f9;
    -webkit-box-shadow: 0px 20px 39px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 20px 39px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 20px 39px -4px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  .golden{
    border-bottom: 4px solid gold;
    border-radius: 20px;
    padding-bottom: 10px; 
  }
  .silver{
    border-bottom: 2px solid rgb(117, 116, 116);
    border-radius: 20px;
    padding-bottom: 10px; 
  }
  .bronze{
    border-bottom: 2px solid #CD7F32;
    border-radius: 20px;
    padding-bottom: 10px; 
  }
  .platinum{
    border-bottom: 2px solid rgb(194, 191, 191);
    border-radius: 20px;
    padding-bottom: 10px; 
  }
  .-anim-scale-:hover{
  transform: scale(1.2);
  transition: 0.5s;
  }
  .-card-title-{
    height: 70%;
    text-align: center;
    /* color: aliceblue; */
    color: white;
   cursor: pointer;
  }
  .-anim-scale-down{
    color: white;
    animation-name: title-animation-scale-down; 
    animation-duration: 3s;
  }
  .-title-anim-fade-{
    color: white;
  animation-name:  title-animation-fade ;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  }
  @keyframes title-animation-scale-down {
    from {transform: scale(1.2);}
    to {transform: scale(1);}
  }
  @keyframes title-animation-fade {
    from {color: #000000;}
    to {color: white; }
  }
  .-btn-{
    cursor: pointer;
    font-weight: 550;
    display: inline;
    /* width: 30%; */
    -webkit-box-shadow: 0px 0px 37px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 37px -15px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 37px -15px rgba(0,0,0,0.75);
    /* color: inherit; */
    color: wheat;
  border-radius: 10px;
  padding: 10px;
    margin: 7px;
  text-align: center;
   background-color: rgb(252, 24, 111);
  transition: 0.5s;
  }
  .-btn-:hover{
    color: wheat;
    background-color: rgb(223, 0, 86);
   transform: scale(1.1);
   transition: 0.5s;
  }
  .-btn-clicked-{
    transition: 0.5s;
    background-color: rgb(105, 105, 105)!important;
    color: #000000 !important;
  }
  .-btn-clicked-:hover{
    transition: 0.5s;
   color: #dcdcdc !important;
   background-color: rgb(50, 50, 50)!important;
  }
 
  .-description-{
    height: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
  }
  
  .-desc-text-{
    color: black;
  }
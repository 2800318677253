.sender {
  width: 50%;
}
.receiver {
  width: 50%;
  margin-left: auto;
}

.alert {
  padding: 5px 10px;
}

/*********  hey! cheery here  ***********/

.replyBox .modal-content {
  width: 75%;
  margin: 0 auto;
  /* max-height: 485px;
  overflow-y: auto; */
}

.call_modal {
  background-color: #5665d2;
}
/* my file upload */
.my_fileUpload {
  position: relative;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.fileUpload {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  /* width: 100%; */
  cursor: pointer;
}

.my_fileUpload > i {
  font-size: 1.5em;
}
button.img_remove {
  position: absolute;
  font-size: 15px;
  color: red;
  top: -8px;
  right: -2px;
}

.upld_img {
  position: relative;
  width: 60px;
}

/* ******audio call modal****** */

.call_modal {
  background-color: #bd3fbe !important;
}
.call_modal .call-body h5 {
  color: #ffffff;
  font-size: 25px;
}
.call_modal .call-body p {
  color: #ffffff;
  font-size: 15px;
}
.call_modal .call-body .audio-img {
  width: 100%;
  max-width: 184px;
  height: 184px;
  border-radius: 50%;
}
.call_modal .receive-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: green;
  color: #ffffff;

  box-shadow: 0 0 18px 5px #44eb168f;
}

.call_modal .receive-btn:hover {
  background-color: #ffffff;
  border: 2px solid green;
  color: green;
}
.call_modal .down-part .decline-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: red;
  color: #ffffff;
  box-shadow: 0 0 18px 5px rgb(0 0 0 / 11%);
}
.call_modal .down-part .decline-btn:hover {
  background-color: #ffffff;
  border: 2px solid red;
  color: red;
}
.call_modal .down-part .down-btn {
  width: 60px;
  height: 60px;
  color: #ffffff;
  font-size: 30px;
}
.call_modal .down-part .down-btn:hover {
  color: #000;
}
.call_modal .down-part {
  border-top: 1px solid #ededed;
  padding: 0.8em 0;

  /* margin-top: 1em; */
}
/* **************audio call end**************** */
/* *************video call modal*************** */
.modal-dialog.video-modal {
  position: relative;
  width: 100%;
  /* margin: 0.5rem; */
  pointer-events: none;
  max-width: 716px;
  object-fit: cover;
}
/* video */
._large_video {
  position: relative;
  border-radius: 13px;

  height: clamp(250px, 62%, 370px);
}
._large_video video {
  width: 100%;
  height: 100%;
  max-height: 400px;
  /* border-radius: 8% 8% 0 0; */
  /* margin-top: 1em; */
}

._large_video img {
  width: 100%;
  height: 100%;
  max-height: 400px;
}

._video_intro.d-flex {
  position: absolute;
  bottom: 5%;
  left: -5%;
}
._video_intro button {
  font-size: 0.85em;
  border-radius: 8px;
  background: #3a394354;
  color: #ffffff;
}
._large_video .video-img {
  position: absolute;
  top: 5%;
  right: 1%;
  width: 85px;
  height: 85px;
}

._large_video .own-video {
  position: absolute;
  top: 5%;
  right: 1%;
  width: 150px;
  height: 150px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

li.log_time {
  font-size: 75%;
  margin: 1rem auto;
  text-align: center;
  position: relative;
}

li.log_time:after {
  position: absolute;
  content: " ";
  left: 0;
  bottom: 7px;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #eaeaea;
}

.log_time span:last-child {
  background: #fff4f4;
  position: relative;
  z-index: 1;
  padding: 0 6px;
  border-radius: 4px;
  margin: 0;
}
.ctext-wrap-content p {
  color: #000;
}
